import Vue from 'vue';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import { ROLES as Roles } from '@/scripts/constants';

Vue.use(AclInstaller);

const DEFAULT_USER_ROLE = 'anonymous';

function generateACLRule(roles) {
  if (!roles || !roles.length) {
    return new AclRule();
  }
  let aclRule;
  roles.forEach(role => {
    aclRule = aclRule ? aclRule.or(role) : new AclRule(role);
  });

  return aclRule.generate();
}

const permissions = {
  public: [ ...Object.values(Roles), DEFAULT_USER_ROLE ],
  view: [ Roles.manager, Roles.admin, Roles.production, Roles.support, Roles.finance, Roles.licensing, Roles.care_coordinator ],
  'system_configs.view': [ Roles.admin, Roles.manager ],
  'system_configs.edit': [ Roles.admin ],
  'acl.view': [ Roles.admin ],
  'institutions.view': [ Roles.admin, Roles.manager ],
  'institutions.edit': [ Roles.manager, Roles.admin ],
  'onboarding.members.view': [ Roles.admin, Roles.manager, Roles.support, Roles.care_coordinator ],
  'member.change.owner.view': [ Roles.admin, Roles.manager, Roles.support ],
  'onboarding.clients.view': [ Roles.admin, Roles.manager, Roles.support, Roles.finance ],
  'onboarding.clients.edit': [ Roles.admin, Roles.manager ],
  'onboarding.clients.billing.edit': [ Roles.admin, Roles.finance ],
  'onboarding.clients.integrations.view': [ Roles.admin, Roles.manager, Roles.support ],
  'onboarding.clients.integrations.edit': [ Roles.admin, Roles.manager ],
  'onboarding.eligibility.view': [ Roles.admin, Roles.manager, Roles.support, Roles.finance ],
  'onboarding.eligibility.configs.edit': [ Roles.admin, Roles.manager ],
  'onboarding.eligibility.whitelist.edit': [ Roles.admin, Roles.manager, Roles.support ],
  'onboarding.eligibility.multiTenant.edit': [ Roles.admin, Roles.manager, Roles.support ],
  'onboarding.invitations.view': [ Roles.admin, Roles.manager, Roles.support ],
  'onboarding.invitations.create': [ Roles.admin, Roles.manager, Roles.support ],
  'onboarding.invitations.edit': [ Roles.admin, Roles.manager, Roles.support ],
  'onboarding.referrals.edit': [ Roles.admin, Roles.manager, Roles.support ],
  'users.create': [ Roles.admin, Roles.manager ],
  'users.list': [ Roles.admin, Roles.manager ],
  'therapists.list': [ Roles.admin, Roles.manager, Roles.licensing ],
  'therapists.edit': [ Roles.admin, Roles.manager, Roles.licensing ],
  'users.view': [ Roles.admin, Roles.manager, Roles.licensing ],
  'users.edit': [ Roles.admin, Roles.manager ],
  'therapist.licenses.edit': [ Roles.admin, Roles.licensing ],
  'tools.sms': [ Roles.admin, Roles.manager, Roles.support ],
  'update.user.roles': [ Roles.admin ],
  'ordermanagement.skuconfigs.view': [ Roles.admin, Roles.support, Roles.production ],
  'ordermanagement.skuconfigs.edit': [ Roles.admin, Roles.support, Roles.production ],
  'ordermanagement.shipvia.view': [ Roles.admin, Roles.support, Roles.production ],
  'ordermanagement.orders.view': [ Roles.admin, Roles.support, Roles.production ],
  'ordermanagement.clientconfigs.edit': [ Roles.admin, Roles.production ],
  'orderManagement.clientException.edit': [ Roles.admin, Roles.support, Roles.production ],
  'orderManagement.shipviaException.edit': [ Roles.admin, Roles.support, Roles.production ],
  'orderManagement.orders.archive': [ Roles.admin, Roles.production ],
  'orderManagement.orders.productionInfo.create': [ Roles.admin, Roles.production ],
  'orderManagement.orders.productionInfo.view': [ Roles.admin, Roles.production ],
  'orderManagement.orders.replace': [ Roles.admin, Roles.production ],
  'orderManagement.manualOrder.create': [ Roles.admin, Roles.support, Roles.production ],
  'order_management.returns.view': [ Roles.admin, Roles.support, Roles.production ],
  'order_management.stock.view': [ Roles.admin, Roles.support, Roles.production ],
  'order_management.cvconfigs.view': [ Roles.admin, Roles.production ],
  'finance.transactions.view': [ Roles.admin, Roles.finance ],
  'finance.manual_transactions.view': [ Roles.admin, Roles.finance ],
  'finance.statements.view': [ Roles.admin, Roles.finance ],
  'finance.claims_batches.view': [ Roles.admin, Roles.finance ],
  'finance.invoices.view': [ Roles.admin, Roles.finance ],
  'finance.billing_reports.view': [ Roles.admin, Roles.finance ],
  'finance.blocklist.view': [ Roles.admin, Roles.finance ],
  'finance.systemPayers.view': [ Roles.admin, Roles.finance, Roles.manager ],
  'onboarding.generic_importer.edit': [ Roles.admin, Roles.manager ],
  'finance.monthly_revenue.view': [ Roles.admin, Roles.finance ],
  'finance.transactions_pepm.view': [ Roles.admin, Roles.finance ],
  'finance.updates.view': [ Roles.admin, Roles.finance ],
  'commissions.payouts.view': [ Roles.admin, Roles.finance, Roles.commissions ],
  'commissions.configurations.view': [ Roles.admin, Roles.finance ],
  'finance_operations.claim_accounts.view': [ Roles.admin, Roles.finance ],
  'finance_operations.claim_follow_up_tasks.view': [ Roles.admin, Roles.finance ],
  'finance_operations.claim_write_off_tasks.view': [ Roles.admin, Roles.finance ],
  'care_ecosystem.view': [ Roles.admin, Roles.manager ],
  'care_ecosystem.edit': [ Roles.admin, Roles.manager ],
  'saml_core.view': [ Roles.admin, Roles.manager ],
  'saml_core.edit': [ Roles.admin ],
  'saml_client.view': [ Roles.admin, Roles.manager, Roles.support, Roles.finance ],
  'revision_requests.list.view': [ Roles.admin, Roles.manager, Roles.support, Roles.finance ],
  'system_executions.view': [ Roles.admin ],
};

const aclRules = {};
Object.entries(permissions).forEach(([ permission, roles ]) => {
  aclRules[permission] = generateACLRule(roles);
});

const ACLinstance = (router, store) => new AclCreate({
  initial: store.getters['User/roles'],
  notfound: '/error/access',
  router,
  acceptLocalRules: true,
  globalRules: aclRules,
});

export { ACLinstance, aclRules, DEFAULT_USER_ROLE };
