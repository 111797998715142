import axios from 'axios';
import Vue from 'vue';
import * as R from 'ramda';
import appConfigs from '@/config';
import { D2C_OPTIONS } from '@/scripts/constants';

export default {
  namespaced: true,
  state: {
    clients: [],
    institutions: [],
    careCoordinatorMembers: [],
    apis: appConfigs.get('VUE_APP_AVAILABLE_ENVIRONMENTS'),
    apiSelected: '',
    aclModuleURL: appConfigs.get('VUE_APP_ACL_MODULE_URL') || '',
    environmentKeys: {
      parentName: '',
      name: '',
    },
  },
  getters: {
    getClients: state => state.clients,
    getInstitutions: state => state.institutions,
    getCareCoordinatorMembers: state => state.careCoordinatorMembers,
    getAvailableApis: state => state.apis,
    getSelectedApi: state => state.apiSelected,
    getSelectedEnv: state => state.environmentKeys.name,
    getEnvironmentParent: state => state.environmentKeys.parentName,
    getEnvironmentConfigs: state => {
      const { parentName, name } = state.environmentKeys;
      return state.apis[parentName][name];
    },
    isSftpSettingsEnabled: state => {
      const { parentName, name } = state.environmentKeys;
      return JSON.parse(R.pathOr(false, [ 'toggle_features', 'sftp_settings_enabled' ], state.apis[parentName][name]));
    },
    getAppointmentsURL: (state, getters) => R.propOr('http://localhost/', 'appointments_url', getters.getEnvironmentConfigs),
    onboardingURL: (state, getters) => R.propOr('http://localhost/', 'onboarding_url', getters.getEnvironmentConfigs),
    onboardingBlossomURL: (state, getters) => R.propOr('http://localhost/', 'onboarding_blossom_url', getters.getEnvironmentConfigs),
    onboardingBecomeURL: (state, getters) => R.propOr('http://localhost/', 'onboarding_become_url', getters.getEnvironmentConfigs),
    onboardingBloomURL: (state, getters) => R.propOr('http://localhost/', 'onboarding_bloom_url', getters.getEnvironmentConfigs),
    getRescheduleURL: (state, getters) => (appointmentUUID, client) => {
      let baseURL;
      switch (client?.configuration?.d2c) {
        case D2C_OPTIONS.BLOSSOM:
          baseURL = getters.onboardingBlossomURL;
          break;
        case D2C_OPTIONS.BECOME:
          baseURL = getters.onboardingBecomeURL;
          break;
        default:
          baseURL = getters.onboardingURL;
          break;
      }
      const path = `appointment/${appointmentUUID}/reschedule`;
      return `${baseURL.replace(/\/$/, '')}/${path}?type=EA`;
    },
    getEmergencyCoverageURL: (state, getters) => (appointmentUUID, client) => {
      const url = new URL(getters.getRescheduleURL(appointmentUUID, client));
      url.searchParams.set('source', 'cc');
      return url.toString();
    },
    showHIESettings: state => {
      const { parentName, name } = state.environmentKeys;
      const val = R.path([ 'toggle_features', 'show_health_integration_exchange_settings' ], state.apis[parentName][name]);
      return !!val && (`${val}`).toLowerCase() !== 'false';
    },
    isRevisionRequestsEnabled: state => {
      const { parentName, name } = state.environmentKeys;
      const val = R.path([ 'toggle_features', 'revision_requests_enabled' ], state.apis[parentName][name]);
      return !!val && ((`${val}`).toLowerCase() === 'true' || val === true);
    },
    isRevisionRequestsEnabledForFinance: state => {
      const { parentName, name } = state.environmentKeys;
      const val = R.path([ 'toggle_features', 'revision_requests_enabled_finance' ], state.apis[parentName][name]);
      return !!val && ((`${val}`).toLowerCase() === 'true' || val === true);
    },
    isZoomProviderEnabled: state => {
      const { parentName, name } = state.environmentKeys;
      const val = R.path([ 'toggle_features', 'zoom_provider_enabled' ], state.apis[parentName][name]);
      return !!val && ((`${val}`).toLowerCase() === 'true' || val === true);
    },
    isMarketingIntegrationEnabled: state => {
      const { parentName, name } = state.environmentKeys;
      const val = R.path([ 'toggle_features', 'marketing_integration_enabled' ], state.apis[parentName][name]);
      return !!val && ((`${val}`).toLowerCase() === 'true' || val === true);
    },
    isSwagStoreEnabled: state => {
      const { parentName, name } = state.environmentKeys;
      const val = R.path([ 'toggle_features', 'swag_store_enabled' ], state.apis[parentName][name]);
      return !!val && ((`${val}`).toLowerCase() === 'true' || val === true);
    },
    isAclOnlyEnabled: state => {
      const { parentName, name } = state.environmentKeys;
      if (!parentName || !name) {
        return false;
      }
      const val = R.path([ 'toggle_features', 'use_acl_only' ], state?.apis[parentName][name]);
      return !!val && ((`${val}`).toLowerCase() === 'true' || val === true);
    },
  },
  mutations: {
    setApi: (state, api) => {
      state.apiSelected = api;
      localStorage.setItem('baseUrl', api);
      axios.defaults.baseURL = `${api.replace(/\/$/, '')}/administration`;
    },
    setEnvClients: (state, clients) => {
      state.clients = clients;
    },
    setEnvInstitutions: (state, institutions) => {
      state.institutions = institutions;
    },
    setCareCoordinatorMembers: (state, careCoordinatorMembers) => {
      state.careCoordinatorMembers = careCoordinatorMembers;
    },
    removeClient(state, clientId) {
      const index = state.clients.findIndex(c => Number(c.id) === Number(clientId));
      if (index >= 0) {
        state.clients.splice(index, 1);
      }
    },
    setEnvironmentKeys(state, environmentKeys) {
      state.environmentKeys = environmentKeys;
      localStorage.setItem('environmentKeys', JSON.stringify(environmentKeys));
    },
  },
  actions: {
    fetchClients({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('v1/clients')
          .then(response => {
            commit('setEnvClients', response.data.payload);
            resolve(response.data.payload);
          })
          .catch(e => {
            Vue.prototype.$noty.error('cannot request clients', e);
            reject(e);
          });
      });
    },
    getClient(_, clientID) {
      return new Promise((resolve, reject) => {
        axios.get(`v1/clients/${clientID}`, { params: { by: 'id' } })
          .then(response => {
            resolve(response.data.client);
          })
          .catch(e => {
            console.error(e);
            reject(e);
          });
      });
    },
    fetchClientsWithIntegration({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('v1/clients/integrations')
          .then(response => {
            commit('setEnvClients', response.data.payload);
            resolve(response.data.payload);
          })
          .catch(e => {
            Vue.prototype.$noty.error('cannot request clients', e);
            reject(e);
          });
      });
    },
    fetchEnvInstitutions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get('v1/institutions', payload).then(response => {
          const envInstitutions = response.data.data;
          commit('setEnvInstitutions', envInstitutions);
          resolve(envInstitutions);
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot request institutions', e);
          reject(e);
        });
      });
    },
    fetchCareCoordinatorMembers({ commit }, { careCoordinatorID, payload }) {
      const url = `v1/care-coordinators/${String(careCoordinatorID)}/members`;
      return new Promise((resolve, reject) => {
        axios.get(url, payload).then(response => {
          const members = response.data;
          commit('setCareCoordinatorMembers', members);
          resolve(members);
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot request cc members', e);
          reject(e);
        });
      });
    },
    fetchAllClients() {
      return axios.get('v1/clients/all')
        .then(response => response.data.payload)
        .catch(err => {
          Vue.prototype.$noty.error('cannot request clients', err);
          return [];
        });
    },
  },
};
