import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      meta: { rule: 'view' },
      components: { default: () => import('../views/MainView.vue') },
      children: [
        {
          name: 'Home',
          path: '/',
          meta: { rule: 'view' },
          component: () => import('../views/Home.vue'),
        },
        {
          name: 'Configs',
          path: '/config',
          meta: { rule: 'system_configs.view' },
          component: () => import('../views/Configs/Config.vue'),
        },
        {
          name: 'Institutions',
          path: '/institutions',
          meta: { rule: 'institutions.view' },
          component: () => import('../views/institutions/list.vue'),
        },
        {
          name: 'RevisionRequests',
          path: '/revision-requests',
          meta: { rule: 'revision_requests.list.view' },
          component: () => import('../views/RevisionRequests/List.vue'),
        },
        {
          path: '/skuconfigs',
          name: 'SKUConfigs',
          meta: { rule: 'ordermanagement.skuconfigs.view' },
          component: () => import('../views/OrderManagement/SkuConfigs/list.vue'),
        },
        {
          path: '/skuconfigs/edit/:productType',
          name: 'SKUConfigsEdit',
          meta: { rule: 'ordermanagement.skuconfigs.edit' },
          component: () => import('../views/OrderManagement/SkuConfigs/edit.vue'),
        },
        {
          path: '/client-configs',
          name: 'ClientConfigs',
          meta: { rule: 'ordermanagement.clientconfigs.edit' },
          component: () => import('../views/OrderManagement/ClientConfigs/ClientConfigsEdit.vue'),
        },
        {
          path: 'order-management/ship-via',
          name: 'ShipVia',
          meta: { rule: 'ordermanagement.shipvia.view' },
          component: () => import('../views/OrderManagement/ShipVia/list.vue'),
        },
        {
          path: '/orders',
          name: 'Orders',
          meta: { rule: 'ordermanagement.orders.view' },
          component: () => import('../views/OrderManagement/Orders/list.vue'),
        },
        {
          path: 'order-management/returns',
          name: 'Returns',
          meta: { rule: 'order_management.returns.view' },
          component: () => import('../views/OrderManagement/returns/list.vue'),
        },
        {
          path: 'order-management/stock',
          name: 'Stock',
          meta: { rule: 'order_management.stock.view' },
          component: () => import('../views/OrderManagement/stock/list.vue'),
        },
        {
          path: 'order-management/computer-vision',
          name: 'CVConfigs',
          meta: { rule: 'order_management.cvconfigs.view' },
          component: () => import('../views/OrderManagement/ComputerVisionConfigs/index.vue'),
        },
        {
          path: '/orders/:orderID',
          name: 'OrderDetails',
          meta: { rule: 'ordermanagement.orders.view' },
          component: () => import('../views/OrderManagement/Orders/details.vue'),
        },
        {
          path: '/professionals',
          name: 'ProfessionalsList',
          meta: { rule: 'therapists.list' },
          component: () => import('@/views/Users/Professionals/List.vue'),
        },
        {
          path: '/professionals/:id/edit',
          name: 'ProfessionalEdit',
          meta: { rule: 'therapists.edit' },
          component: () => import('@/views/Users/Professionals/Edit/Index.vue'),
        },
        {
          path: '/professionals/:id/edit-deprecated',
          name: 'ProfessionalEditDeprecated',
          meta: { rule: 'therapists.edit' },
          component: () => import('@/views/Users/Professionals/EditDeprecated/Index.vue'),
        },
        {
          path: '/onboarding/eligibility-attempts',
          name: 'ListEligibilityAttempts',
          meta: { rule: 'onboarding.members.view' },
          component: () => import('../views/Onboarding/ListEligibilityAttempts.vue'),
        },
        {
          path: '/onboarding/eligibility-failures',
          name: 'ListEligibilityFailures',
          meta: { rule: 'onboarding.members.view' },
          component: () => import('../views/Onboarding/EligibilityFailures/List.vue'),
        },
        {
          path: '/onboarding/eligibility-failures/:uuid',
          name: 'EligibilityFailureDetails',
          meta: { rule: 'onboarding.members.view' },
          component: () => import('../views/Onboarding/EligibilityFailures/Details.vue'),
        },
        {
          path: '/onboarding/enrollments',
          name: 'ListEnrollmentsV2',
          meta: { rule: 'onboarding.members.view' },
          component: () => import('../views/Onboarding/ListEnrollmentsV2.vue'),
        },
        {
          path: '/onboarding/whitelist',
          name: 'ClientWhitelist',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Onboarding/ClientWhitelist.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/billing',
          name: 'ClientBillingSettings',
          meta: { rule: 'onboarding.clients.billing.edit' },
          component: () => import('../views/Onboarding/Client/ClientBillingSettings.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/billing/history/:configurationId',
          name: 'ClientBillingSettingsHistory',
          meta: { rule: 'onboarding.clients.billing.edit' },
          props: true,
          component: () => import('../views/Onboarding/Client/ClientBillingSettingsHistory.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/invitations',
          name: 'ClientMemberInvitations',
          meta: { rule: 'onboarding.invitations.view' },
          component: () => import('../views/Onboarding/MemberInvitations/ListMemberInvitations.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/invitations/new',
          name: 'NewClientMemberInvitations',
          meta: { rule: 'onboarding.invitations.create' },
          component: () => import('../views/Onboarding/MemberInvitations/CreateMemberInvitation.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/invitations/edit/:uuid',
          name: 'EditClientMemberInvitations',
          meta: { rule: 'onboarding.invitations.edit' },
          component: () => import('../views/Onboarding/MemberInvitations/EditMemberInvitation.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/referrals',
          name: 'ClientReferralsConfig',
          meta: { rule: 'onboarding.referrals.edit' },
          component: () => import('../views/Onboarding/Client/ClientReferralsConfig.vue'),
        },
        {
          path: '/onboarding/query-finder',
          name: 'QueryFinder',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Onboarding/queryFinder.vue'),
        },
        {
          path: '/onboarding/eligibility/checker',
          name: 'TestEligibility',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Onboarding/Eligibility/EligibilityTest.vue'),
        },
        {
          path: '/onboarding/eligibility/:clientId/checker',
          name: 'TestEligibilityWithClient',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Onboarding/Eligibility/EligibilityTest.vue'),
        },
        {
          path: '/onboarding/integrations',
          name: 'ClientIntegrations',
          meta: { rule: 'onboarding.clients.integrations.view' },
          component: () => import('../views/Onboarding/Client/ClientIntegrations.vue'),
        },
        {
          path: '/marketing-touchpoints',
          name: 'ListMarketingTouchpoints',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/Client/MarketingPlans/List.vue'),
        },
        {
          path: '/payers',
          name: 'Payers',
          meta: { rule: 'finance.systemPayers.view' },
          component: () => import('../views/Finance/SystemPayers/list.vue'),
        },
        {
          path: '/onboarding/integrations/:clientId',
          name: 'ClientIntegrationsEdit',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/Onboarding/Client/ClientIntegrationsEdit.vue'),
        },
        {
          path: '/onboarding/eligibility/configs',
          name: 'EligibilityConfigs',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Onboarding/Eligibility/EligibilityConfigs.vue'),
        },
        {
          path: '/clients/netsuite/list',
          name: 'ClientsNetSuiteList',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Client/NetSuiteList.vue'),
        },
        {
          path: '/eligibility/file-tracking',
          name: 'EligibilityFileTracking',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Onboarding/Eligibility/ProductionFileTracking.vue'),
        },
        {
          path: '/sftp/providers',
          name: 'SFTPProvidersList',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Provider/ProvidersList.vue'),
        },
        {
          path: '/sftp/providers/:providerId',
          name: 'SFTPProviderConfig',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Provider/ProviderConfig.vue'),
        },
        {
          path: '/sftp/importers',
          name: 'SFTPGenericImporterList',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/GenericImporter/GenericImporterList.vue'),
        },
        {
          path: '/sftp/importers/new',
          name: 'SFTPGenericImporterCreate',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/GenericImporter/GenericImporterCreate.vue'),
        },
        {
          path: '/sftp/importers/:importerId',
          name: 'SFTPGenericImporterEdit',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/GenericImporter/GenericImporterEdit.vue'),
        },
        {
          path: '/sftp/clients',
          name: 'SFTPClientsList',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Client/ClientList.vue'),
        },
        {
          path: '/sftp/clients/:clientId',
          name: 'SFTPClientConfig',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Client/ClientConfigs.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/sftp/create-user',
          name: 'SFTPCreateUser',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Users/Create.vue'),
        },
        {
          path: '/sftp/users/internal',
          name: 'SFTPInternalUsersList',
          props: {
            serverType: 'internal',
          },
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Users/List.vue'),
        },
        {
          path: '/sftp/users/external',
          name: 'SFTPExternalUsersList',
          props: {
            serverType: 'external',
          },
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Users/List.vue'),
        },
        {
          path: '/sftp/users/:server/:username',
          name: 'SFTPUserDetails',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Users/Details.vue'),
        },
        {
          path: '/sftp/users/:server/:username/edit',
          name: 'SFTPUserEdit',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Users/Edit.vue'),
        },
        {
          path: '/sftp/create-group',
          name: 'SFTPCreateGroup',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Groups/Create.vue'),
        },
        {
          path: '/sftp/groups/internal',
          name: 'SFTPInternalGroupsList',
          props: {
            serverType: 'internal',
          },
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Groups/List.vue'),
        },
        {
          path: '/sftp/groups/external',
          name: 'SFTPExternalGroupsList',
          props: {
            serverType: 'external',
          },
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Groups/List.vue'),
        },
        {
          path: '/sftp/groups/:server/:name',
          name: 'SFTPGroupDetails',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Groups/Details.vue'),
        },
        {
          path: '/sftp/groups/:server/:name/edit',
          name: 'SFTPGroupEdit',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/SFTP/Groups/Edit.vue'),
        },
        {
          path: '/sftp/remote-servers',
          name: 'SFTPRemoteServersList',
          meta: { rule: 'system_executions.view' },
          component: () => import('../views/SFTP/RemoteServers/List.vue'),
        },
        {
          path: '/sftp/remote-servers/create',
          name: 'SFTPRemoteServerCreate',
          meta: { rule: 'system_executions.view' },
          component: () => import('../views/SFTP/RemoteServers/Create.vue'),
        },
        {
          path: '/sftp/remote-servers/:uuid',
          name: 'SFTPRemoteServerView',
          meta: { rule: 'system_executions.view' },
          component: () => import('../views/SFTP/RemoteServers/View.vue'),
        },
        {
          path: '/sftp/remote-servers/:uuid/edit',
          name: 'SFTPRemoteServerEdit',
          meta: { rule: 'system_executions.view' },
          component: () => import('../views/SFTP/RemoteServers/Edit.vue'),
        },
        {
          path: '/sftp/system-executions',
          name: 'SFTPSystemExecutions',
          meta: { rule: 'system_executions.view' },
          component: () => import('../views/SFTP/SystemExecutions/SystemExecutionsList.vue'),
        },
        {
          path: '/sftp/system-executions/:uuid',
          name: 'SFTPSystemExecutionAttempts',
          meta: { rule: 'system_executions.view' },
          component: () => import('../views/SFTP/SystemExecutions/SystemExecutionsAttempts.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/sftp/bigquery-importer/:providerId',
          name: 'SFTPBigQueryImporterHistory',
          meta: { rule: 'system_executions.view' },
          component: () => import('../views/SFTP/BigQueryImporter/ProviderHistory.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/onboarding/clients/:clientId/eligibility',
          name: 'ClientEligibilitySettings',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Onboarding/Client/ClientEligibilitySettings.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/eligibility/history',
          name: 'ClientEligibilityIngestionHistory',
          meta: { rule: 'onboarding.eligibility.view' },
          component: () => import('../views/Onboarding/Eligibility/History.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/generic-importer',
          name: 'ClientGenericImporterSettings',
          meta: { rule: 'onboarding.generic_importer.edit' },
          component: () => import('../views/Onboarding/GenericImporter/ListConfigurations.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/generic-importer/:fileTypeId/create',
          name: 'AddNewGenericFileTypeConfiguration',
          meta: { rule: 'onboarding.generic_importer.edit' },
          component: () => import('../views/Onboarding/GenericImporter/AddConfiguration.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/generic-importer/:fileTypeId/:providerId',
          name: 'ViewGenericFileTypeConfiguration',
          meta: { rule: 'onboarding.generic_importer.edit' },
          component: () => import('../views/Onboarding/GenericImporter/ViewConfiguration.vue'),
        },
        {
          path: '/onboarding/clients/:clientId/generic-importer/:fileTypeId/:providerId/edit',
          name: 'UpdateGenericFileTypeConfiguration',
          meta: { rule: 'onboarding.generic_importer.edit' },
          component: () => import('../views/Onboarding/GenericImporter/UpdateConfiguration.vue'),
        },
        {
          path: '/onboarding/client/edit/:clientID?',
          name: 'EditClient',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/Client/Edit/Index.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/onboarding/client/bulk-update-configs',
          name: 'ClientBulkUpdateConfigs',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/Client/BulkUpdateConfigs/Index.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/client/:clientID/marketing-plan/:marketingPlanUUID?',
          name: 'MarketingPlanEdit',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/Client/MarketingPlans/Edit.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/client/:clientID/manage-professionals',
          name: 'ClientManageProfessionals',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/Client/ProfessionalConfigs/Index.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/client/:clientID/contract/:contractID?',
          name: 'ClientContracts',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/Client/Contracts/Edit.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/order-management/client-exception/new',
          name: 'NewClientException',
          meta: { rule: 'orderManagement.clientException.edit' },
          component: () => import('../views/OrderManagement/SkuConfigs/ClientExceptions/EditClientException.vue'),
        },
        {
          path: '/order-management/client-exception/:id/edit',
          name: 'EditClientException',
          meta: { rule: 'orderManagement.clientException.edit' },
          component: () => import('../views/OrderManagement/SkuConfigs/ClientExceptions/EditClientException.vue'),
        },
        {
          path: '/order-management/ship-via-exception/new',
          name: 'NewShipViaException',
          meta: { rule: 'orderManagement.shipviaException.edit' },
          component: () => import('../views/OrderManagement/ShipVia/ClientExceptions/new.vue'),
        },
        {
          path: '/order-management/ship-via-exception/:id/edit',
          name: 'EditShipViaException',
          meta: { rule: 'orderManagement.shipviaException.edit' },
          component: () => import('../views/OrderManagement/ShipVia/ClientExceptions/edit.vue'),
        },
        {
          path: '/orderManagement/ManualOrder/new',
          name: 'NewManualOrder',
          meta: { rule: 'orderManagement.manualOrder.create' },
          component: () => import('../views/OrderManagement/Orders/CreateManualOrder.vue'),
        },
        {
          path: '/orderManagement/return/new',
          name: 'NewReturnOrder',
          meta: { rule: 'orderManagement.manualOrder.create' },
          component: () => import('../views/OrderManagement/returns/new.vue'),
        },
        {
          path: '/finance/transactions',
          name: 'FinanceTransactions',
          meta: { rule: 'finance.transactions.view' },
          component: () => import('../views/Finance/Transactions/list.vue'),
        },
        {
          path: '/finance/import-transactions',
          name: 'FinanceManualTransactions',
          meta: { rule: 'finance.manual_transactions.view' },
          component: () => import('../views/Finance/ManualTransactions/index.vue'),
        },
        {
          path: '/finance/import-transactions/details/:importID',
          name: 'FinanceManualTransactionDetails',
          meta: { rule: 'finance.transactions.view' },
          component: () => import('../views/Finance/ManualTransactions/Transactions/details.vue'),
        },
        {
          path: '/finance/import-transactions-pepm/details/:importID',
          name: 'FinanceManualTransactionPEPMDetails',
          meta: { rule: 'finance.transactions.view' },
          component: () => import('../views/Finance/ManualTransactions/TransactionsPEPM/details.vue'),
        },
        {
          path: '/finance/statements',
          name: 'FinanceStatementsPage',
          meta: { rule: 'finance.statements.view' },
          component: () => import('../views/Finance/Statements/page.vue'),
        },
        {
          path: '/finance/statements/:statementID',
          name: 'FinanceStatementsDetails',
          meta: { rule: 'finance.statements.view' },
          component: () => import('../views/Finance/Statements/details.vue'),
        },
        {
          path: '/finance/claims-batches',
          name: 'FinanceClaimsBatchesList',
          meta: { rule: 'finance.claims_batches.view' },
          component: () => import('../views/Finance/ClaimsBatches/list.vue'),
        },
        {
          path: '/finance/claims-batches/:claimsBatchID',
          name: 'FinanceClaimsBatchDetails',
          meta: { rule: 'finance.claims_batches.view' },
          component: () => import('../views/Finance/ClaimsBatches/details.vue'),
        },
        {
          path: '/finance/invoices',
          name: 'FinanceInvoicesList',
          meta: { rule: 'finance.invoices.view' },
          component: () => import('../views/Finance/Invoices/list.vue'),
        },
        {
          path: '/finance/invoices/:invoiceID',
          name: 'FinanceInvoicesDetails',
          meta: { rule: 'finance.invoices.view' },
          component: () => import('../views/Finance/Invoices/detail.vue'),
        },
        {
          path: '/finance/billing-reports',
          name: 'FinanceBillingReportsPage',
          meta: { rule: 'finance.billing_reports.view' },
          component: () => import('../views/Finance/BillingReports/page.vue'),
        },
        {
          path: '/finance/blocklist',
          name: 'FinanceBlocklist',
          meta: { rule: 'finance.blocklist.view' },
          component: () => import('../views/Finance/Blocklist/list.vue'),
        },
        {
          path: '/finance/monthly-revenue',
          name: 'FinanceMonthlyRevenueList',
          meta: { rule: 'finance.monthly_revenue.view' },
          component: () => import('../views/Finance/MonthlyRevenue/list.vue'),
        },
        {
          path: '/finance/monthly-revenue/:clientId/:yearMonth',
          name: 'FinanceMonthlyRevenueDetails',
          meta: { rule: 'finance.monthly_revenue.view' },
          component: () => import('../views/Finance/MonthlyRevenue/details.vue'),
        },
        {
          path: '/finance/transactions-pepm',
          name: 'FinanceTransactionsPEPMList',
          meta: { rule: 'finance.transactions_pepm.view' },
          component: () => import('../views/Finance/TransactionsPEPM/list.vue'),
        },
        {
          path: '/finance/updates',
          name: 'FinanceUpdatesList',
          meta: { rule: 'finance.updates.view' },
          component: () => import('../views/Finance/Updates/list.vue'),
        },
        {
          path: '/finance/updates/:requestId',
          name: 'FinanceUpdatesDetails',
          meta: { rule: 'finance.updates.view' },
          component: () => import('../views/Finance/Updates/details.vue'),
        },
        {
          path: '/commissions/statements',
          name: 'Statements',
          meta: { rule: 'commissions.payouts.view' },
          component: () => import('../views/Commissions/Statements/index.vue'),
        },
        {
          path: '/commissions/statements/:statementId',
          name: 'Details',
          meta: { rule: 'commissions.payouts.view' },
          component: () => import('../views/Commissions/Statements/details.vue'),
        },
        {
          path: '/commissions/transactions',
          name: 'Transactions',
          meta: { rule: 'commissions.payouts.view' },
          component: () => import('../views/Commissions/Transactions/index.vue'),
        },
        {
          path: '/commissions/deals',
          name: 'Deals',
          meta: { rule: 'commissions.configurations.view' },
          component: () => import('../views/Commissions/Deals/index.vue'),
        },
        {
          path: '/commissions/deals/:dealID',
          name: 'DealsDetails',
          meta: { rule: 'commissions.payouts.view' },
          component: () => import('../views/Commissions/Deals/Details/index.vue'),
        },
        {
          path: '/commissions/rvps',
          name: 'RVPs',
          meta: { rule: 'commissions.configurations.view' },
          component: () => import('../views/Commissions/RVPs/index.vue'),
        },
        {
          path: '/commissions/rvps/create',
          name: 'RVPCreate',
          meta: { rule: 'commissions.configurations.view' },
          component: () => import('../views/Commissions/RVPs/create.vue'),
        },
        {
          path: '/commissions/rvps/:id',
          name: 'RVPEditAndDetails',
          meta: { rule: 'commissions.configurations.view' },
          component: () => import('../views/Commissions/RVPs/editAndDetails.vue'),
        },
        {
          path: '/finance-operations/claim-accounts',
          name: 'FinanceOperationsClaimAccounts',
          meta: { rule: 'finance_operations.claim_accounts.view' },
          component: () => import('../views/FinanceOperations/ClaimAccounts/list.vue'),
        },
        {
          path: '/finance-operations/claim-accounts/:id',
          name: 'FinanceOperationsClaimAccountsDetails',
          meta: { rule: 'finance_operations.claim_accounts.view' },
          component: () => import('../views/FinanceOperations/ClaimAccounts/details.vue'),
        },
        {
          path: '/finance-operations/claim-follow-up-tasks',
          name: 'FinanceOperationsClaimFollowUpTasks',
          meta: { rule: 'finance_operations.claim_follow_up_tasks.view' },
          component: () => import('../views/FinanceOperations/ClaimFollowUpTasks/list.vue'),
        },
        {
          path: '/finance-operations/claim-follow-up-tasks/:id',
          name: 'FinanceOperationsClaimFollowUpTasksDetails',
          meta: { rule: 'finance_operations.claim_follow_up_tasks.view' },
          component: () => import('../views/FinanceOperations/ClaimFollowUpTasks/details.vue'),
        },
        {
          path: '/finance-operations/claim-write-off-tasks',
          name: 'FinanceOperationsClaimWriteOffTasks',
          meta: { rule: 'finance_operations.claim_write_off_tasks.view' },
          component: () => import('../views/FinanceOperations/ClaimWriteOffTasks/index.vue'),
        },
        {
          path: '/finance-operations/claim-write-off-tasks/:id',
          name: 'FinanceOperationsClaimWriteOffTasksDetails',
          meta: { rule: 'finance_operations.claim_write_off_tasks.view' },
          component: () => import('../views/FinanceOperations/ClaimWriteOffTasks/details.vue'),
        },
        {
          path: '/care-ecosystem/vendors',
          name: 'CareEcosystemVendors',
          meta: { rule: 'care_ecosystem.view' },
          component: () => import('../views/CareEcosystem/Vendor/list.vue'),
        },
        {
          path: '/care-ecosystem/vendors/new',
          name: 'CareEcosystemVendorsNew',
          meta: { rule: 'care_ecosystem.edit' },
          props: true,
          component: () => import('../views/CareEcosystem/Vendor/edit.vue'),
        },
        {
          path: '/care-ecosystem/vendors/edit/:id',
          name: 'CareEcosystemVendorsEdit',
          meta: { rule: 'care_ecosystem.edit' },
          props: true,
          component: () => import('../views/CareEcosystem/Vendor/edit.vue'),
        },
        {
          path: '/care-ecosystem/services',
          name: 'CareEcosystemServices',
          meta: { rule: 'care_ecosystem.view' },
          component: () => import('../views/CareEcosystem/Service/list.vue'),
        },
        {
          path: '/care-ecosystem/services/new',
          name: 'CareEcosystemServicesNew',
          meta: { rule: 'care_ecosystem.edit' },
          props: true,
          component: () => import('../views/CareEcosystem/Service/edit.vue'),
        },
        {
          path: '/care-ecosystem/services/edit/:id',
          name: 'CareEcosystemServicesEdit',
          meta: { rule: 'care_ecosystem.edit' },
          props: true,
          component: () => import('../views/CareEcosystem/Service/edit.vue'),
        },
        {
          path: '/acl',
          name: 'AclLists',
          meta: { rule: 'acl.view' },
          props: true,
          component: () => import('../views/Acl/List/Index.vue'),
        },
        {
          path: '/acl/groups/:id',
          name: 'AclGroupDetails',
          meta: { rule: 'acl.view' },
          props: true,
          component: () => import('../views/Acl/GroupDetail.vue'),
        },
        {
          path: '/acl/users/:uuid',
          name: 'AclUserDetails',
          meta: { rule: 'acl.view' },
          props: true,
          component: () => import('../views/Acl/UserDetail.vue'),
        },
        {
          path: '/acl/users/:user_uuid/roles',
          name: 'AclUserRoles',
          meta: { rule: 'acl.view' },
          props: true,
          component: () => import('../views/Acl/UserRoles.vue'),
        },
        {
          path: '/acl/users/:user_uuid/groups',
          name: 'AclUserGroups',
          meta: { rule: 'acl.view' },
          props: true,
          component: () => import('../views/Acl/UserGroups.vue'),
        },
        {
          path: '/acl/groups/:group_id/assigned-roles',
          name: 'AclGroupLCRoles',
          meta: { rule: 'acl.view' },
          props: true,
          component: () => import('../views/Acl/GroupLCRoles.vue'),
        },
      ],
    },
    { // Saml Core
      name: 'SamlCore',
      path: '/saml/core',
      meta: { rule: 'saml_core.view' },
      components: { default: () => import('../views/MainView.vue') },
      children: [
        {
          name: 'SamlCoreConfigurationList',
          path: 'configurations',
          meta: { rule: 'saml_core.view' },
          component: () => import('../views/Saml/Core/Configurations/List.vue'),
        },
        {
          name: 'SamlCoreConfigurationNew',
          path: 'configurations/new',
          meta: { rule: 'saml_core.edit' },
          component: () => import('../views/Saml/Core/Configurations/New.vue'),
        },
        {
          name: 'SamlCoreConfigurationView',
          path: 'configurations/:uuid',
          meta: { rule: 'saml_core.view' },
          component: () => import('../views/Saml/Core/Configurations/View.vue'),
        },
        {
          name: 'SamlCorePartnerList',
          path: 'partners',
          meta: { rule: 'saml_core.view' },
          component: () => import('../views/Saml/Core/Partners/List.vue'),
        },
        {
          name: 'SamlCorePartnerNew',
          path: 'partners/new',
          meta: { rule: 'saml_core.edit' },
          component: () => import('../views/Saml/Core/Partners/New.vue'),
        },
        {
          name: 'SamlCorePartnerEdit',
          path: 'partners/:key/edit',
          meta: { rule: 'saml_core.edit' },
          component: () => import('../views/Saml/Core/Partners/Edit.vue'),
        },
        {
          name: 'SamlCoreConnectionList',
          path: 'connections',
          meta: { rule: 'saml_core.view' },
          component: () => import('../views/Saml/Core/Connections/List.vue'),
        },
        {
          name: 'SamlCoreConnectionNew',
          path: 'connections/new',
          meta: { rule: 'saml_core.edit' },
          component: () => import('../views/Saml/Core/Connections/New.vue'),
        },
        {
          name: 'SamlCoreConnectionView',
          path: 'connections/:key',
          meta: { rule: 'saml_core.view' },
          component: () => import('../views/Saml/Core/Connections/View.vue'),
        },
        {
          name: 'SamlCoreConnectionEdit',
          path: 'connections/:key/edit',
          meta: { rule: 'saml_core.edit' },
          component: () => import('../views/Saml/Core/Connections/Edit.vue'),
        },
      ],
    },
    { // SAML Client
      path: '/onboarding/clients',
      meta: { rule: 'system_configs.view' },
      components: { default: () => import('../views/MainView.vue') },
      children: [
        {
          path: '',
          name: 'ListOnboardingClients',
          meta: { rule: 'onboarding.clients.view' },
          component: () => import('../views/Client/List.vue'),
        },
        {
          name: 'ListClientSSOIntegrations',
          path: ':client_id/sso/integrations',
          meta: { rule: 'saml_client.view' },
          component: () => import('../views/Client/SSO/List.vue'),
        },
        {
          name: 'ClientSamlIntegrationCustomers',
          path: ':client_id/sso/integrations/:uuid/customers',
          meta: { rule: 'saml_client.view' },
          component: () => import('../views/Client/SSO/Edit.vue'),
        },
      ],
    },
    {
      path: '/configs/:group',
      meta: { rule: 'system_configs.view' },
      components: { default: () => import('../views/MainView.vue') },
      children: [
        {
          path: '',
          meta: { rule: 'system_configs.view' },
          component: () => import('../views/Config2.vue'),
        },
        {
          path: '/configs/referral-rules',
          name: 'Referral Rules',
          meta: { rule: 'system_configs.view' },
          component: () => import('../views/Config2.vue'),
        },
      ],
    },
    {
      path: '/assets-manager/translations',
      name: 'AssetsManagerTranslateSystem',
      meta: { rule: 'onlyAdmin' },
      components: { default: () => import('../views/MainView.vue') },
      children: [
        {
          path: '/exercises',
          name: 'Exercises',
          meta: { rule: 'system_configs.view' },
          component: () => import('../views/Configs/Exercises.vue'),
        },
        {
          path: '/users',
          name: 'UsersList',
          meta: { rule: 'users.list' },
          component: () => import('../views/Users/users-list.vue'),
        },
        {
          path: '/my-profile',
          name: 'MyProfile',
          meta: { rule: 'view' },
          component: () => import('../views/Users/Profile.vue'),
        },
        {
          path: '/users/new',
          name: 'NewUser',
          meta: { rule: 'users.create' },
          component: () => import('../views/Users/NewUser.vue'),
        },
        {
          path: '/users/edit/:id',
          name: 'UserEdit',
          meta: { rule: 'users.view' },
          component: () => import('../views/Users/UserEdit.vue'),
          props: route => ({ ...route.params }),
        },
        {
          path: '/members-bulk-transfers',
          name: 'MembersBulkTransfer',
          meta: { rule: 'users.view' },
          component: () => import('@/views/ClinicalOps/MembersBulkTransfer.vue'),
        },
        {
          path: '/rule-editor',
          name: 'RuleEditor',
          meta: { rule: 'users.view' },
          component: () => import('@/views/ClinicalOps/RuleEditor.vue'),
        },
        {
          path: '/ops/search-member/',
          name: 'OpsSearchProgram',
          meta: { rule: 'onboarding.members.view' },
          component: () => import('../views/OpsSearchProgram/Index.vue'),
        },
        {
          path: '/ops/member-change-owner/:patientID',
          name: 'MemberChangeOwner',
          meta: { rule: 'member.change.owner.view' },
          component: () => import('../views/ClinicalOps/MemberChangeOwner.vue'),
        },
        {
          path: '/ops/unsubscribe/',
          name: 'Unsubscribe',
          meta: { rule: 'onboarding.members.view' },
          component: () => import('../views/Configs/Unsubscribe.vue'),
        },
        {
          path: '/ops/vip-accounts/',
          name: 'VIPAccounts',
          meta: { rule: 'onboarding.members.view' },
          component: () => import('../views/ClinicalOps/VipAccounts.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      meta: { rule: 'public' },
      component: () => import('../views/Login.vue'),
    },
    {
      path: '/error/access',
      meta: { rule: 'public' },
      components: { default: () => import('../views/MainView.vue') },
      children: [ { path: '/', meta: { rule: 'public' }, component: () => import('../views/error/Access.vue') } ],
    },
    {
      path: '*',
      name: '404',
      meta: { rule: 'public' },
      component: () => import('../views/error/404.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.User.isAuthReady) {
    await store.dispatch('User/init');
  }

  const isLoggedIn = store.getters['User/isLoggedIn'];
  const requiresAuth = to.meta.rule !== 'public';

  if (requiresAuth && !isLoggedIn) return next({ name: 'Login' });
  if (!requiresAuth && isLoggedIn) return next({ name: 'Home' });

  // maintain special query params
  const { mode: toMode } = to.query;
  const { mode: fromMode } = from.query;
  let forceQueryParam = false;
  if (!!fromMode && !toMode) {
    to.query.mode = fromMode;
    forceQueryParam = true;
  }

  if (forceQueryParam) {
    return next(to);
  }

  return next();
});

export default router;
